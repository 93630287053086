<template>
  <div class="People_boxS">
    <div class="People">
      <ul>
        <li
          v-for="(item, index) in data"
          :key="index"
          @click="
            red = index
            compData = item.components
          "
          :class="{ red1: red === index }"
        >
        <div class="time-boxD">
          <div :class=" red === index ? 'time-boxS':'' "></div>
        </div>
          <p>{{ item.name }} <span>{{item.gary}}</span></p>
        </li>
      </ul>
    </div>
    <keep-alive>
      <component :is="compData"></component>
    </keep-alive>
  </div>
</template>

<script>
import qbrys from '@c/Home/modular/FunOperation'
import bmjgs from '@c/Home/modular/FunView'
export default {
  components: {
    qbrys,
    bmjgs
  },
  data() {
    return {
      compData: 'bmjgs',
      red: 0,
      data: [
        { name: '设置操作权限', components: 'bmjgs', gary: '(编辑与查看)' },
        { name: '设置查看权限', components: 'qbrys', gary: '(仅能查看)' }
      ]
    }
  },
  computed: {
    
  },
  methods: {
    Essential() {
      this.compData = 'qbrys'
    },
    structure() {
      this.compData = 'bmjgs'
    }
  }
}
</script>

<style scoed lang="scss">
.People_boxS {
  position: relative;
  .People {
    position: absolute;
    left: 50%;
    top:20px;
    transform: translate(-50%);
    width: 100%;
    color: #f6f6f3;
  & > ul {
    display: flex;
    justify-content:center;
    margin-top: 30px;
    & > li {
      color: #333;
      font-size: 15px;
      width: 300px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor:pointer
    }
    span{
      color: gray;
      font-size: 14px;
    }
    .red1 {
      color: red;
    }
    .time-boxD{
      width: 20px;
      height: 20px;
      border: 1px solid red;
      border-radius: 50%;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .time-boxS{
      width: 14px;
      height: 14px;
      background-color: red;
      border-radius: 50%;
    }
  }
}
}

</style>
