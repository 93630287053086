<template>
  <div class="node-box">
    <div class="node-left">
      <div class="node-leftquree">
        <p>选择人员</p>
        <div class="node-input">
          <el-input v-model="filterText"></el-input>
          <button @click="btnA">查询</button>
        </div>
      </div>
      <div class="node-tree">
        <div class="node-treebtn">
          <div :class="i===0?'node-treesel':''" @click="handlechange1">按组织架构</div>
          <div :class="i===1?'node-treesel':''" @click="handlechange2">按角色</div>
        </div>
        <el-tree
        class="node-treebody"
        :data="data"
        v-if="i==0"
        show-checkbox
        node-key="id"
        :filter-node-method="filterNode"
        :props="defaultProps"
        @check-change="handlechange"
        :default-expand-all='true'
        ref="tree">
        </el-tree>
         <el-tree
        class="node-treebody"
        :data="data1"
        v-if="i==1"
        show-checkbox
        node-key="id"
        :filter-node-method="filterNode"
        :props="defaultProps"
        @check-change="handlechange"
        :default-expand-all='true'
        ref="tree">
        </el-tree>
      </div>
    </div>

    <div class="node-right">
      <div class="node-rightbtn">
        保存
      </div>
      <div>
        <p class="node-righttitle">已选人员</p>
        <div class="node-per">
          <div class="node-persone" v-for="(item,i) in personnel" :key="i">
            <img :src="item.img" alt="">
            <p class="node-perlabel">{{item.label}}</p>
            <p class="node-oper" @click="handledelete(i)">移除</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handlechange1() {
      this.i = 0
    },
    handlechange2() {
      this.i = 1
    },
    btnA() {
      this.$refs.tree.filter(this.filterText)
    },
    filterNode(value, data) {
      console.log(data.label)
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handledelete(i) {
      this.$confirm('此操作将删除该人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.personnel.splice(i, 1)

        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handlechange(info, nodestatus, nodesubstatus) {
      // console.log('nodesubstatus', info)
      if (nodestatus === true && !info.children) {
        if (this.personnel.length < 20) {
          this.personnel.unshift({
            id: info.id,
            label: info.label,
            img: info.img
          })
        } else {
          this.$message({
            type: 'info',
            message: '已超越最大限度,无法继续勾选!!!'
          })
        }
      } else {
        this.personnel.forEach(item => {
          if (info.id === item.id) {
            this.personnel.splice(item, 1)
          }
        })
      }
    }
  },
  data() {
    return {
      filterText: '',
      i: 0,
      data: [],
      data1: [],
      personnel: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-main {
  
  background: #f2f6fc;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
}
.node-box {
  width: 100%;
  height: 771px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-top: 20px;
  .node-left {
    width: 450px;
    height: 100%;
    margin-top: 95px;
    .node-lefttitle {
      display: flex;
      align-items: center;
      margin-left: 22px;
      padding-top: 22px;
      img {
        width: 22px;
        height: 22px;
      }
      h4 {
        color: #333333;
        font-size: 15px;
        margin-left: 11px;
      }
    }
    .node-leftnum {
      width: 100%;
      height: 30px;
      margin-top: 20px;
      margin-left: 54px;
      font-size: 15px;
      color: #999999;
      span {
        color: #fc5160;
      }
    }
    .node-leftquree {
      .node-input {
        display: flex;
        margin-top: 21px;
      }
      p {
        font-size: 15px;
        margin-left: 43px;
        margin-top: 25px;
        color: #333;
      }
      input {
        border: 1px solid #cccccc;
        margin-left: 32px;
        width: 300px;
        height: 50px;
        border-radius: 10px;
      }
      button {
        border: none;
        background: linear-gradient(to right, #fc4353, #ff5e41);
        margin-left: 20px;
        width: 97px;
        height: 50px;
        border-radius: 10px;
        font-size: 15px;
        color: white;
      }
    }
  }
  .node-right {
    flex: 1;
    height: 100%;
    border-radius: 10px;
    margin-left: 50px;
    margin-right: 30px;
    .node-rightbtn{
      font-size: 15px;
      cursor:pointer;
      width: 90px;
      height: 40px;
      background-color: #fc4353;
      color: white;
      line-height: 40px;
      text-align: center;
      border-radius: 10px;
      position: absolute;
      right: 30px;
      top: 40px;
    }
    .node-righttitle {
      font-size: 15px;
      color: #333333;
      margin-top: 180px;
      margin-left: 36px;
    }
    .node-per {
      width: 100%;
      height: 538px;
      margin-top: 30px;
      box-shadow: 0px 4px 10px #a8a8a8;
      overflow-y: auto;
      .node-persone:hover {
        background-color: #f9f9f9;
      }
      .node-persone {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          margin-left: 41px;
          border-radius: 50%;
        }
        p {
          font-size: 15px;
        }
        .node-perlabel {
          width: 100px;
          margin-left: 20px;
          color: #333;
        }
        .node-oper {
          margin-left: 220px;
          color: #333;
        }
      }
    }
  }
  .node-tree {
    width: 405px;
    height: 538px;
    box-shadow: 6px 2px 8px #bfbfd1;
    margin-top: 20px;
    margin-left: 32px;
    font-size: 15px;
    overflow-y: auto;
    .node-treebtn{
      display: flex;
      div{
        width: 80px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        color: #FC4452;
        border-radius: 10px;
        font-size: 13px;
        border: 1px solid #FC4452;
        margin-left: 30px;
        margin-top: 30px;
        cursor:pointer
      }
      .node-treesel{
        background-color: #FC4452;
        color: #fff;
      }
    }
    .node-treebody {
      margin-left: 30px;
      margin-top: 30px;
    }
  }
  .node-tree::-webkit-scrollbar {
    width: 5px;
  }
  .node-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 2px;
    background: #c8c8c8;
  }
  .node-tree::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f6f6fa;
  }
  .node-per::-webkit-scrollbar {
    width: 5px;
  }
  .node-per::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 2px;
    background: #c8c8c8;
  }
  .node-per::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f6f6fa;
  }
}
</style>
